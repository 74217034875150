<template>
  <div>
    <h2 class="card-title">Transaction #{{ transaction.id }}</h2>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <h3 class="card-title">Transaction Details</h3>
            <table class="table table-responsive table-borderless">
              <tbody>
                <tr>
                  <td>User</td>
                  <td>
                    <router-link :to="{ name: 'admin.users.show', params: { id: transaction.user.id }}">{{ transaction.user.name }}</router-link>
                  </td>
                </tr>
                <tr>
                  <td>Payment For</td>
                  <td>{{ transaction.type }}</td>
                </tr>
                <tr>
                  <td>Payment Method</td>
                  <td>{{ transaction.type }}</td>
                </tr>
                <tr>
                  <td>Reference</td>
                  <td>{{ transaction.reference }}</td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td>{{ transaction.currency }} {{ transaction.amount }}</td>
                </tr>
                <tr>
                  <td>Date</td>
                  <td>{{ transaction.date }}</td>
                </tr>
                <tr>
                  <td>Payer</td>
                  <td>{{ transaction.payer }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <div class="mt-4" v-if="transaction.type == 'signal'">
              <h3 class="card-title">Signal Supscription Details</h3>
              <table class="table table-responsive-borderless table">
                <tbody>
                  <tr>
                    <td>Date Susbscribed</td>
                    <td>{{ transaction.more.start }}</td>
                  </tr>
                  <tr>
                    <td>Months</td>
                    <td>{{ transaction.more.months }}</td>
                  </tr>
                  <tr>
                    <td>Expiry Date</td>
                    <td>{{ transaction.more.expiry }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-4" v-if="transaction.type == 'community'">
              <h3 class="card-title">Community Supscription Details</h3>
              <table class="table table-responsive-borderless table">
                <tbody>
                  <tr>
                    <td>Date Susbscribed</td>
                    <td>{{ transaction.more.start }}</td>
                  </tr>
                  <tr>
                    <td>Months</td>
                    <td>{{ transaction.more.months }}</td>
                  </tr>
                  <tr>
                    <td>Expiry Date</td>
                    <td>{{ transaction.more.expiry }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-4" v-if="transaction.type == 'academy'">
              <h3 class="card-title">Academy Payment Details</h3>
              <table class="table table-responsive-borderless table">
                <tbody>
                  <tr>
                    <td>Section</td>
                    <td>{{ transaction.more.section }}</td>
                  </tr>
                  <tr>
                    <td>Course</td>
                    <td>{{ transaction.more.course }}</td>
                  </tr>
                  <tr>
                    <td>Week</td>
                    <td>{{ transaction.more.week_number }}. {{ transaction.more.week }}</td>
                  </tr>
                  <tr>
                    <td>Videos</td>
                    <td>{{ transaction.more.videos }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      transaction: { user: {} }
    }
  },

  methods: {
    fetch() {
      this.$axios.get(`/api/v1/admin/transactions/${this.$route.params.id}`).then(response => {
        this.transaction = response.data.transaction
      })
    }
  }
}
</script>